import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { Flex, Box } from "@rebass/grid/emotion";

import { breadcrumbs } from "shared/helpers/schema";

import Card from "../../components/Card";
import Hero from "../../components/Hero";
import Page from "../../components/Page";
import Tile from "../../components/Tile";
import Kranicon from "../../components/Kranicon";
import { LinkButton } from "../../components/Button";
import PageBlock from "../../components/PageBlock";

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  li {
    padding: 6px 10px;
    margin: 10px;
    background-color: #eee;
    list-style: none;
  }
  @media (max-width: 499px) {
    margin: -5px;
    li {
      width: 100%;
      margin: 5px;
    }
  }
`;

const Leistungen = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.servicesImage.fluid}
        icon={<Kranicon icon="leistungen" />}
        title="Weitere Leistungen für Ihre Immobilie"
        intro="Lassen Sie sich Ihren Spezialist empfehlen – ehrlich, geprüft und regional."
      />
    }
  >
    <Helmet
      title="Weitere Leistungen"
      meta={[
        {
          name: "description",
          content:
            "Wir beraten Sie auch individuell zur Renovierung, Sanierung, Trockenlegung oder Gartengestaltung. Ein erprobtes Netzwerk an Partnern steht immer zur Seite.",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Leistungen",
              link: "/leistungen/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]}>
          <Tile style={{ height: "100%" }}>
            <Card>
              <h2>Immobiliensanierung und Renovierung</h2>
              <p>
                Der Umzug in ein neues Haus ist stets eine aufregende
                Angelegenheit, die aber einer gewissen Vorbereitung bedarf.
                Neben der Abwicklung des eigentlichen Umzugs ist die
                Instandsetzung des neuen Eigenheims zuweilen eine große Aufgabe.
                Je nach Zustand können kleinere Renovierungsarbeiten ausreichen,
                während in anderen Fällen umfassende Sanierungsmaßnahmen
                erforderlich sind. Dies gilt auch für älteres Wohneigentum, das
                auf Vordermann gebracht werden soll.
              </p>
              <p style={{ marginBottom: 0 }}>
                <LinkButton to="/kontakt/">Empfehlung erhalten</LinkButton>
              </p>
            </Card>
          </Tile>
        </Box>
        <Box width={[1, 1 / 2]}>
          <Tile style={{ height: "100%" }}>
            <Card>
              <h2>Professionelle Immobilien Trockenlegung</h2>
              <p>
                Feuchtigkeit im Mauerwerk kann nicht nur zu Schimmelbildung
                führen, sondern der gesamten Bausubstanz massiv zusetzen.
                Folglich ist eine Immobilien Trockenlegung eine Maßnahme, die
                nicht nur zur Lebensqualität in den eigenen vier Wänden
                beiträgt, sondern essentiell für die Rettung des Hauses ist. Aus
                diesem Grund sollten Sie sich ausschließlich an erfahrene Profis
                wenden. Bei mir können Sie sich absoluter Kompetenz und einer
                Rundum-Betreuung sicher sein.
              </p>
              <p style={{ marginBottom: 0 }}>
                <LinkButton to="/kontakt/">Empfehlung erhalten</LinkButton>
              </p>
            </Card>
          </Tile>
        </Box>
        <Box width={[1, 1 / 2]}>
          <Tile style={{ height: "100%" }}>
            <Card>
              <h2>Ansprechende Garten- und Landschaftsgestaltung</h2>
              <p>
                Das Haus ist fertig – nun sind die Außenanlagen herzustellen?
                Wege und Einfahrten zu pflastern? Nicht nur das Gebäude selbst,
                sondern auch der Außenbereich ist maßgebend für den ersten
                Eindruck, den ein Grundstück auf Besucher macht. Wenn auch Sie
                zu den Menschen gehören, die gerne im Garten relaxen und zudem
                großen Wert auf ein gepflegtes Ambiente legen, holen Sie sich
                ein Garten- und Landschaftsgestalter.
              </p>
              <p style={{ marginBottom: 0 }}>
                <LinkButton to="/kontakt/">Empfehlung erhalten</LinkButton>
              </p>
            </Card>
          </Tile>
        </Box>
        <Box width={[1, 1 / 2]}>
          <Tile style={{ height: "100%" }}>
            <Card>
              <h2>Homestaging</h2>
              <p>
                Wir bereiten Ihre Immobilie professionell und mit Liebe zum
                Detail für den Verkauf vor. Beginnend bei Tipps für schnelle
                Ausbesserungen bis zur zeitweisen Einrichtung mit Möbeln bieten
                wir alles an, damit sich Interessenten bei der Besichtigung so
                richtig wohl fühlen.
              </p>
              <p style={{ marginBottom: 0 }}>
                <LinkButton to="/homestaging/">Mehr dazu</LinkButton>
              </p>
            </Card>
          </Tile>
        </Box>
      </Flex>
      <Tile>
        <Card>
          <h2>Unser Netzwerk mit weiteren Profis</h2>
          <TagList>
            <li>Immobilieninvest/ Geldanlage / Immobilienfonds</li>
            <li>Immobilieneinrichtungen</li>
            <li>Individuelle Einrichtungskonzepte – maßgefertigt</li>
            <li>Möbelbau</li>
            <li>Küchen</li>
            <li>Immobilienwertanalysen</li>
            <li>Immobilienrecht</li>
            <li>Immobilienumzug</li>
            <li>Immobilienneubau</li>
            <li>Immobilienförderungen</li>
            <li>Immobilienverwaltung</li>
            <li>Immobilienfinanzierungen- / Umschuldung</li>
            <li>Immobilienrecht-/ Steuern</li>
          </TagList>
        </Card>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    servicesImage: imageSharp(
      fluid: { originalName: { regex: "/services/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default Leistungen;
